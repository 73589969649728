<template>
  <div>
    <el-table
      :data="tableData"
      border
      style="width: 100%"
      :span-method="arraySpanMethod"
    >
      <template slot="empty">
        <div class="noData">ไม่พบข้อมูลที่ค้นหา</div>
      </template>
      <el-table-column prop="id" label="ลำดับ" width="120" align="center">
      </el-table-column>
      <el-table-column
        prop="title"
        header-align="center"
        label="หัวข้อแบบประเมินความพึงพอใจ"
      >
        <template slot-scope="scope">
          <div :class="scope.$index === 6 ? 'font-weight-bold font-24' : ''">
            {{ scope.row.title }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="score" align="center" label="คะแนนประเมินเฉลี่ย">
        <template slot-scope="scope"
          ><div
            v-if="scope.row.score"
            :class="scope.$index === 6 ? 'font-weight-bold font-24' : ''"
          >
            {{ scope.row.score | dotRoundDown }} คะแนน
          </div></template
        >
      </el-table-column>
      <el-table-column
        prop="date"
        align="center"
        label="วันที่และเวลาที่อัปเดต"
      >
        <template slot-scope="scope"
          ><div>
            {{ scope.row.date | dateTimeTh }}
          </div></template
        >
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  props: {
    tableData: {
      typeof: Array,
      default: []
    }
  },
  methods: {
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === this.tableData.length - 1) {
        if (columnIndex === 1) {
          return [1, 1];
        }
        if (columnIndex === 2) {
          return [2, 3];
        }
      }
    }
  }
};
</script>
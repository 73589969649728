<template>
  <DashboardAdminTemplate>
    <div class="pd-x-5 pd-b-3" v-loading="loading">
      <div>
        <el-row>
          <el-col :span="24">
            <div class="is-flex ai-center">
              <h2>สร้างลิงก์ข้อมูลโครงการ</h2>
            </div>
          </el-col>
          <el-col :span="24" class="is-flex ai-center js-end">
            <el-button
              type="primary"
              size="default"
              @click="dialogCreaet = !dialogCreaet"
              >สร้างลิงก์โครงการ</el-button
            >
          </el-col>
          <el-col :span="24" class="mg-y-5 bordered rounded pd-5">
            <el-form label-position="top" label-width="100px" :model="form">
              <el-row class="">
                <el-col :span="24">
                  <el-row :gutter="20">
                    <el-col :span="24" :sm="8">
                      <el-form-item
                        label="ค้นหาชื่อโครงการ/แหล่งที่มาโครงการ"
                        class="padding-less"
                      >
                        <el-input
                          v-model="form.name"
                          placeholder="พิมพ์ชื่อโครงการ/แหล่งที่มาโครงการที่ต้องการค้นหา"
                          @input="setSearchProject(600)"
                          clearable
                        ></el-input>
                      </el-form-item>
                    </el-col>

                    <el-col :span="24" :sm="8">
                      <el-form-item
                        label="วันที่เปิดรับสมัคร"
                        class="padding-less"
                      >
                        <el-date-picker
                          class="w-100"
                          v-model="form.date"
                          type="daterange"
                          range-separator="ถึง"
                          format="dd/MM/yyyy"
                          start-placeholder="วันที่เปิดรับสมัคร"
                          end-placeholder="วันที่ปิดรับสมัคร"
                          @change="setSearchProject"
                        >
                        </el-date-picker>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24" :sm="8">
                      <el-form-item
                        label="วันที่จัดโครงการ"
                        class="padding-less"
                      >
                        <el-date-picker
                          class="w-100"
                          v-model="form.dateJoin"
                          type="daterange"
                          range-separator="ถึง"
                          format="dd/MM/yyyy"
                          start-placeholder="วันที่จัดโครงการ"
                          end-placeholder="วันที่จัดโครงการ"
                          @change="setSearchProject"
                        >
                        </el-date-picker>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </el-form>
          </el-col>
          <el-col :span="24" class="mg-y-6">
            <el-table
              border
              v-loading="isLoading"
              :data="tableProject.data"
              style="width: calc(100% - 5px)"
              :row-class-name="tableRowClassName"
            >
              <template slot="empty">
                <div class="noData">ไม่พบข้อมูลที่ค้นหา</div>
              </template>
              <el-table-column
                label="ชื่อโครงการ"
                align="left"
                header-align="center"
                :width="widthMax <= 800 ? '202px' : ''"
              >
                <template slot-scope="scope">
                  <div
                    :style="
                      checkDateExp(scope.row.endDate) == true
                        ? 'color:#9c9c9c'
                        : ''
                    "
                  >
                    {{ scope.row.projectName }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="แหล่งที่มาโครงการ"
                align="center"
                header-align="center"
                :width="widthMax <= 800 ? '180px' : ''"
              >
                <template slot-scope="scope">
                  <div
                    :style="
                      checkDateExp(scope.row.endDate) == true
                        ? 'color:#9c9c9c'
                        : ''
                    "
                  >
                    {{ scope.row.referName }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="วันที่เปิดรับสมัคร"
                align="center"
                header-align="center"
                :width="widthMax >= 800 ? '200px' : ''"
              >
                <template slot-scope="scope">
                  <div
                    :style="
                      checkDateExp(scope.row.endDate) == true
                        ? 'color:#9c9c9c'
                        : ''
                    "
                  >
                    {{
                      moment(scope.row.startDate)
                        .add(543, "year")
                        .format("D MMM yy") +
                      " - " +
                      moment(scope.row.endDate)
                        .add(543, "year")
                        .format("D MMM yy")
                    }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="วันที่จัดโครงการ"
                align="center"
                header-align="center"
                :width="widthMax >= 800 ? '200px' : ''"
              >
                <template slot-scope="scope">
                  <div
                    :style="
                      checkDateExp(scope.row.endDate) == true
                        ? 'color:#9c9c9c'
                        : ''
                    "
                  >
                    <span
                      v-if="
                        scope.row.startJoin != null && scope.row.endJoin != null
                      "
                    >
                      {{
                        moment(scope.row.startJoin)
                          .add(543, "year")
                          .format("D MMM yy")
                      }}
                    </span>
                    <span v-else>-</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="สร้างโครงการเมื่อ"
                align="center"
                header-align="center"
                :width="widthMax >= 800 ? '200px' : ''"
              >
                <template slot-scope="scope">
                  <div
                    :style="
                      checkDateExp(scope.row.endDate) == true
                        ? 'color:#9c9c9c'
                        : ''
                    "
                  >
                    {{ scope.row.createdAt | dateTimeTh }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" width="120px">
                <template slot-scope="scope">
                  <div
                    class="is-flex js-center ai-center"
                    style="color: #959595"
                  >
                    <el-tooltip
                      content="คัดลอกลิงก์"
                      :open-delay="500"
                      placement="bottom-end"
                    >
                      <div
                        @click="
                          copyLink(
                            redirectUrl +
                              scope.row.iv +
                              '&openExternalBrowser=1'
                          )
                        "
                        class="mg-r-6"
                      >
                        <i class="fas fa-link font-16 cr-pointer"></i>
                      </div>
                    </el-tooltip>
                    <el-tooltip
                      content="ดาวน์โหลด QR Code"
                      :open-delay="500"
                      placement="bottom-end"
                    >
                      <div class="mg-r-6">
                        <i
                          class="fas fa-qrcode font-16 cr-pointer"
                          @click="downloadURI(scope.row.id)"
                        ></i>
                      </div>
                    </el-tooltip>
                    <el-tooltip
                      content="แก้ไข"
                      :open-delay="500"
                      placement="bottom-end"
                    >
                      <div @click="modalEdit(scope.row.id)" class="mg-r-6">
                        <i class="fas fa-pen font-16 cr-pointer"></i>
                      </div>
                    </el-tooltip>
                    <el-tooltip
                      content="ดูผู้เข้าร่วมโครงการ"
                      :open-delay="500"
                      placement="bottom-end"
                    >
                      <div>
                        <i
                          class="fas fa-eye font-16 cr-pointer"
                          @click="$router.push('/refer-user/' + scope.row.id)"
                        ></i>
                      </div>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div class="mg-y-3">
              <div class="block text-center">
                <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page.sync="currentPage"
                  :page-sizes="[15, 30, 50, 100, 500]"
                  :page-size="limit"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="projectTotal"
                >
                </el-pagination>
              </div>
            </div>
          </el-col>
        </el-row>
        <!-- <el-dialog v-if="dialogDelete" :visible.sync="dialogDelete" center>
          <div class="text-center">
            <h2 class="mg-t-0">ยืนยันลบข้อมูลโครงการ</h2>
            <p>
              คุณมีข้อมูลผู้ใช้งานใน
              <span class="text-primary">โครงการประชาสัมพันธ์ระบบ TERAK</span>
              อยู่ดังนี้
            </p>
            <div class="mg-y-3">
              <div class="block text-center">
                <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page.sync="currentPage"
                  :page-size="15"
                  layout="total, prev, pager, next"
                  :total="projectTotal"
                >
                </el-pagination>
              </div>
            </div>
            <a
              @click="dialogDelete = false"
              class="text-grey-light font-24 mg-t-1"
              >ยกเลิก</a
            >
          </div>
        </el-dialog> -->
        <el-dialog
          v-if="dialogCreaet"
          :visible.sync="dialogCreaet"
          center
          :before-close="clearData"
          :class="checkDateExp(ruleForm.endDate) == true ? 'expDialog' : ''"
        >
          <h2 class="text-center mg-t-0">
            <span v-if="!createSuccess || (createSuccess && !showEdit)"
              >สร้างลิงก์ข้อมูลโครงการ</span
            >
            <span v-else>แก้ไขลิงก์โครงการ</span>
          </h2>
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-position="top"
            class="demo-ruleForm"
          >
            <el-row>
              <el-col :span="24">
                <el-row :gutter="20" class="custom-row">
                  <el-col :span="24" :sm="11">
                    <el-form-item
                      label="ชื่อโครงการ"
                      prop="projectName"
                      class="padding-less"
                    >
                      <el-input v-model="ruleForm.projectName"></el-input>
                    </el-form-item>
                    <el-form-item
                      label="แหล่งที่มาโครงการ"
                      prop="referName"
                      class="padding-less"
                    >
                      <el-input v-model="ruleForm.referName"></el-input>
                    </el-form-item>
                    <el-form-item
                      label="วันที่เปิดรับสมัคร"
                      prop="opening"
                      class="padding-less is-required"
                    >
                      <el-date-picker
                        class="w-100"
                        v-model="date"
                        type="daterange"
                        format="dd/MM/yyyy"
                        range-separator="ถึง"
                        start-placeholder="วันที่เปิดรับสมัคร"
                        end-placeholder="วันที่ปิดรับสมัคร"
                      >
                      </el-date-picker>
                    </el-form-item>
                    <el-form-item
                      label="วันที่จัดโครงการ"
                      prop="openningDateJoin"
                      class="padding-less is-required"
                    >
                      <el-date-picker
                        class="w-100"
                        v-model="dateJoin"
                        type="date"
                        format="dd/MM/yyyy"
                        placeholder="วันที่จัดโครงการ"
                      >
                      </el-date-picker>
                    </el-form-item>
                  </el-col>
                  <el-col
                    :span="24"
                    :sm="2"
                    style="position: relative"
                    v-if="showEdit || createSuccess"
                  >
                    <div class="box-line-next">
                      <i class="fas fa-chevron-right box-arrow"></i>
                    </div>
                  </el-col>
                  <el-col :span="24" :sm="11" v-if="showEdit || createSuccess">
                    <el-row :gutter="10">
                      <el-col :span="24" :sm="16">
                        <el-form-item label="ลิงก์โครงการ" class="padding-less">
                          <el-input
                            :value="
                              redirectUrl +
                              ruleForm.iv +
                              '&openExternalBrowser=1'
                            "
                            disabled
                          ></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="24" :sm="8">
                        <el-button
                          type="primary"
                          size="default"
                          class="w-100 btnCopy"
                          @click="
                            copyLink(
                              redirectUrl +
                                ruleForm.iv +
                                '&openExternalBrowser=1'
                            )
                          "
                          >คัดลอกลิงก์</el-button
                        >
                      </el-col>
                      <el-col :span="24">
                        <div class="box-qr">
                          <div
                            style="
                              width: 220px;
                              height: 220px;
                              padding: 10px;
                              border: 1px solid #c4c4c4;
                            "
                          >
                            <v-qr
                              :text="
                                redirectUrl +
                                ruleForm.iv +
                                '&openExternalBrowser=1'
                              "
                              :logo="logoRes"
                              qrcodeColor="#000000ff"
                              backgroundColor="#ffffffff"
                              :size="200"
                              :logoSize="50"
                              :margin="0"
                              :ratio="1"
                              level="M"
                              @on-finish="getLink"
                            />
                          </div>

                          <el-button
                            type="primary"
                            size="default"
                            class="btnCopy"
                          >
                            <a @click="downloadURI(ruleForm.id)">ดาวน์โหลด</a>
                          </el-button>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-form>
          <el-button
            type="primary"
            class="mg-t-6"
            @click="createProject"
            v-if="!createSuccess"
            >สร้างลิงก์โครงการ</el-button
          >
          <el-button
            type="primary"
            class="mg-t-6"
            @click="editProject"
            v-if="showEdit"
            >แก้ไขลิงก์โครงการ</el-button
          >
          <div
            slot="footer"
            class="text-left"
            v-if="checkDateExp(ruleForm.endDate) == true"
          >
            <p class="cr-pointer" @click="clearData">ปิดรับสมัครโครงการแล้ว</p>
          </div>
        </el-dialog>
        <v-qr
          style="display: none"
          :text="redirectUrl + ruleForm.iv + '&openExternalBrowser=1'"
          :logo="logoRes"
          qrcodeColor="#000000ff"
          backgroundColor="#ffffffff"
          :size="500"
          :logoSize="100"
          :margin="20"
          :ratio="1"
          level="M"
          @on-finish="imgQr"
        />
      </div>
    </div>
  </DashboardAdminTemplate>
</template>
<script>
import DashboardAdminTemplate from "@/template/DashboardAdminTemplate";
import Complacency from "@/components/feedback/Complacency";
import { HTTP } from "@/service/axios";
import moment from "moment";
import src2 from "@/assets/logoQr3.png";
export default {
  components: {
    DashboardAdminTemplate,
    Complacency
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
    logoRes() {
      return this.$store.state.linkLogo64;
    }
  },

  data() {
    var checkOpening = (rule, value, calback) => {
      if (this.date == null) {
        calback(new Error("กรุณาเลือกวันที่เปิดรับสมัคร"));
      } else {
        if (this.date.length == 0) {
          calback(new Error("กรุณาเลือกวันที่เปิดรับสมัคร"));
        } else {
          calback();
        }
      }
    };
    var checkOpeningJoin = (rule, value, calback) => {
      if (this.dateJoin == null) {
        calback(new Error("กรุณาเลือกวันที่เข้าร่วมโครงการ"));
      } else {
        if (this.dateJoin.length == 0) {
          calback(new Error("กรุณาเลือกวันที่เข้าร่วมโครงการ"));
        } else {
          calback();
        }
      }
    };
    return {
      dialogDelete: false,
      widthMax: window.innerWidth,
      src2,
      redirectUrl: process.env.VUE_APP_URL + "sign-in?code=",
      url: process.env.VUE_APP_URL,
      showEdit: false,
      createSuccess: false,
      date: [],
      dateJoin: "",
      ruleForm: {
        projectName: "",
        referName: "",
        startJoin: "",
        endJoin: ""
      },
      form: {
        name: "",
        date: [],
        dateJoin: []
      },
      filterTime: null,
      projectTotal: null,
      tableProject: [],
      dialogCreaet: false,
      loading: false,
      isLoading: false,
      tableData: [],
      search: "all",
      limit: 15,
      rules: {
        projectName: [
          {
            required: true,
            message: "กรุณากรอกชื่อโครงการ",
            trigger: "blur"
          }
        ],
        referName: [
          {
            required: true,
            message: "กรุณากรอกแหล่งที่มาโครงการ",
            trigger: "blur"
          }
        ],
        opening: [
          {
            trigger: ["change"]
          },
          { validator: checkOpening, trigger: ["change"] }
        ],
        openningDateJoin: [
          {
            trigger: ["change"]
          },
          { validator: checkOpeningJoin, trigger: ["change"] }
        ]
      },
      currentPage: 1,
      linkImg: "",
      linkImgDownload: "",
      linkQr: "",
      firstRow: false
    };
  },
  mounted() {
    this.getProject();
  },
  methods: {
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex === 0 && this.firstRow) {
        return "success-row";
      }
      return "";
    },
    downloadURI(id) {
      this.getProjectById(id);
      this.isLoading = true;

      this.clearData();
      setTimeout(() => {
        var link = document.createElement("a");
        link.setAttribute("download", "qrcode_" + id);
        link.href = this.linkImg;
        document.body.appendChild(link);
        link.click();
        link.remove();
        this.isLoading = false;
      }, 800);
    },
    imgQrCode(dataUrl) {
      this.linkImgDownload = dataUrl;
    },
    imgQr(dataUrl) {
      this.linkImg = dataUrl;
    },
    checkDateExp(val) {
      let now = new Date();
      let dateNow = moment(now).format("yyyy-MM-DD");
      let dateEnd = moment(val).format("yyyy-MM-DD");
      if (dateNow > dateEnd) {
        return true;
      } else if (dateNow == dateEnd) {
        return false;
      } else {
        return false;
      }
    },
    getProject() {
      this.isLoading = true;
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.get(`refer?page=${this.currentPage}&limit=${this.limit}`)
        .then(res => {
          if (res.data.success) {
            this.tableProject = res.data.obj;
            this.projectTotal = res.data.obj.total_page;
            this.loading = false;
            this.isLoading = false;
          }
        })
        .catch(e => {
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          } else {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
          console.log("confirmAssessment error", e);
        })
        .finally(() => {
          this.loading = false;
          this.isLoading = false;
        });
    },
    copyLink(val) {
      var text = val;
      var elem = document.createElement("textarea");
      document.body.appendChild(elem);
      elem.value = text;
      elem.select();
      document.execCommand("copy");
      document.body.removeChild(elem);
      this.$message({
        type: "success",
        message: "คัดลอกลิงก์โครงการสำเร็จ!"
      });
    },
    createProject() {
      this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          this.createSuccess = true;
          this.isLoading = true;
          HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
          HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
          let obj = {
            projectName: this.ruleForm.projectName,
            referName: this.ruleForm.referName,
            startDate: this.date[0],
            endDate: this.date[1],
            startJoin: moment(this.dateJoin).format("yyyy-MM-DD"),
            endJoin: moment(this.dateJoin).format("yyyy-MM-DD")
          };
          HTTP.post(`refer`, obj)
            .then(res => {
              if (res.data.success) {
                this.$message({
                  type: "success",
                  message: "สร้างลิงก์โครงการสำเร็จ!"
                });
                this.getProjectById(res.data.obj.id);
                this.getProject();
                this.createSuccess = true;
                this.isLoading = false;
              }
            })
            .catch(e => {
              this.isLoading = false;
              if (e == "Error: Request failed with status code 401") {
                this.checkAuth();
              } else {
                if (e != "Error: timeout of 120000ms exceeded") {
                  this.alertCatchError(e);
                }
              }
              console.log("confirmAssessment error", e);
            })
            .finally(() => {
              this.isLoading = false;
            });
        }
      });
    },
    editProject() {
      this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          this.isLoading = true;
          HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
          HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
          let obj = {
            id: this.ruleForm.id,
            projectName: this.ruleForm.projectName,
            referName: this.ruleForm.referName,
            startDate: moment(this.date[0]).format("yyyy-MM-DD"),
            endDate: moment(this.date[1]).format("yyyy-MM-DD"),
            startJoin: moment(this.dateJoin).format("yyyy-MM-DD"),
            endJoin: moment(this.dateJoin).format("yyyy-MM-DD")
          };
          HTTP.put(`refer`, obj)
            .then(res => {
              if (res.data.success) {
                this.isLoading = false;
                this.$message({
                  type: "success",
                  message: "แก้ไขลิงก์โครงการสำเร็จ!"
                });
                this.form.name = "";
                this.form.date = [];
                this.form.dateJoin = [];
                setTimeout(() => {
                  this.handleCurrentChange(1, true);
                }, 300);

                this.clearData();
              }
            })
            .catch(e => {
              this.isLoading = false;
              if (e == "Error: Request failed with status code 401") {
                this.checkAuth();
              } else {
                if (e != "Error: timeout of 120000ms exceeded") {
                  this.alertCatchError(e);
                }
              }
              console.log("confirmAssessment error", e);
            });
        }
      });
    },
    getProjectById ( idProject )
    {
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.get(`refer/edit/${idProject}`)
        .then(res => {
          if (res.data.success) {
            this.ruleForm = res.data.obj[0];
            this.date = [
              moment(this.ruleForm.startDate).format("yyyy-MM-DD"),
              moment(this.ruleForm.endDate).format("yyyy-MM-DD")
            ];
            this.dateJoin = moment(this.ruleForm.endJoin).format("yyyy-MM-DD");
          }
        })
        .catch(e => {
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          } else {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
          console.log("confirmAssessment error", e);
        });
    },
    modalEdit(idProject) {
      this.showEdit = true;
      this.createSuccess = true;
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.get(`refer/edit/${idProject}`)
        .then(res => {
          if (res.data.success) {
            this.ruleForm = res.data.obj[0];
            this.date = [
              moment(this.ruleForm.startDate).format("yyyy-MM-DD"),
              moment(this.ruleForm.endDate).format("yyyy-MM-DD")
            ];
            if (this.ruleForm.startJoin == null) {
              this.dateJoin = "";
            } else {
              this.dateJoin = moment(this.ruleForm.startJoin).format(
                "yyyy-MM-DD"
              );
            }
            this.dialogCreaet = true;
          }
        })
        .catch(e => {
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          } else {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
          console.log("confirmAssessment error", e);
        });
    },
    handleCurrentChange(val, edit = false) {
      this.currentPage = val;
      if (edit) {
        this.firstRow = true;
      } else {
        this.firstRow = false;
      }
      this.searchProject();
    },
    handleSizeChange(val) {
      this.limit = val;
      this.handleCurrentChange(1);
    },
    setSearchProject(time = 0) {
      this.currentPage = 1;
      this.firstRow = false;
      clearTimeout(this.filterTime);
      this.filterTime = setTimeout(() => {
        this.searchProject();
      }, time);
    },
    searchProject() {
      this.isLoading = true;
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      let startDate = "";
      let endDate = "";
      let startJoin = "";
      let endJoin = "";
      if (this.form.date == null) {
        startDate = "";
        endDate = "";
      } else {
        if (typeof this.form.date[0] != "undefined") {
          startDate = moment(this.form.date[0]).format("yyyy-MM-DD");
        } else {
          startDate = "";
        }
        if (typeof this.form.date[1] != "undefined") {
          endDate = moment(this.form.date[1]).format("yyyy-MM-DD");
        } else {
          endDate = "";
        }
      }
      if (this.form.dateJoin == null) {
        startJoin = "";
        endJoin = "";
      } else {
        if (typeof this.form.dateJoin[0] != "undefined") {
          startJoin = moment(this.form.dateJoin[0]).format("yyyy-MM-DD");
        } else {
          startJoin = "";
        }
        if (typeof this.form.dateJoin[1] != "undefined") {
          endJoin = moment(this.form.dateJoin[1]).format("yyyy-MM-DD");
        } else {
          endJoin = "";
        }
      }
      HTTP.get(
        `refer/search?key=${this.form.name}&start=${startDate}&end=${endDate}&startJoin=${startJoin}&endJoin=${endJoin}&page=${this.currentPage}&limit=${this.limit}`
      )
        .then(res => {
          if (res.data.success) {
            this.tableProject = res.data.obj;
            this.projectTotal = res.data.obj.total_page;
            this.loading = false;
            this.isLoading = false;
          }
        })
        .catch(e => {
          this.loading = false;
          this.isLoading = false;
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          } else {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
          console.log("confirmAssessment error", e);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    clearData() {
      this.dialogCreaet = false;
      this.showEdit = false;
      this.createSuccess = false;
      this.ruleForm = {
        projectName: "",
        referName: "",
        startJoin: "",
        endJoin: ""
      };
      this.date = [""];
      this.dateJoin = "";
    },
    getLink(val) {
      this.linkQr = val;
    },
    print() {
      // const el = this.$refs.printMe;
      // const options = {
      //   type: "dataURL",
      // };
      // this.output = await this.$html2canvas(el, options);
      // console.log(this.output);
      this.clearData();
      if (this.linkQr != "") {
        this.isLoading = false;

        setTimeout(() => {
          var link = document.createElement("a");
          link.setAttribute("download", "QRCodeProject");
          link.href = this.linkQr;
          document.body.appendChild(link);
          link.click();
          link.remove();
          this.isLoading = true;
        }, 800);
      }
    }
  }
};
</script>
